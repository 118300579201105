import "./assets/styles/globals.scss"

import {Routes, Route} from "react-router-dom"
import {ModalProvider} from "./modalContext"
import ChatPage from "./pages/ChatPage/ChatPage"
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy"
import {models as chatModels} from "./configs/chat"
import NotificationPermission from "./NotificationPermission"

function App() {
	return (
		<div className='App'>
			<NotificationPermission />
			<ModalProvider>
				<Routes>
					<Route path='privacy-policy' element={<PrivacyPolicy />} />
					<Route path='*' element={<ChatPage models={chatModels} />} />
				</Routes>
			</ModalProvider>
		</div>
	)
}

export default App
