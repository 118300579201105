import {useContext} from "react"
import {ModalContext} from "../../../../modalContext"
import {GLOBAL_BRAND} from "../../../../utils/constants"

import styles from "./Gifts.module.scss"

const message = `Здравствуйте! <br /> Напишите свой номер, и я подробно расскажу обо всех доступных преимуществах от ${GLOBAL_BRAND} и нашего салона.`

export default function Gifts({maxBenefit}) {
	const {handleModal} = useContext(ModalContext)

	return (
		<>
			<div className={styles.gifts}>
				<div onClick={() => handleModal(message)} className={styles.giftItem}>
					<img src={"/images/chat/gifts/1.svg"} alt='Иконка' />
					<div className={styles.text}>Кредитная ставка от 0,01%</div>
				</div>
				<div onClick={() => handleModal(message)} className={styles.giftItem}>
					<img src={"/images/chat/gifts/2.svg"} alt='Иконка' />
					<div className={styles.text}>Рассрочка от 0.01%</div>
				</div>
				<div onClick={() => handleModal(message)} className={styles.giftItem}>
					<img src={"/images/chat/gifts/3.svg"} alt='Иконка' />
					<div className={styles.text}>Сигнализация в подарок</div>
				</div>
			</div>
			<div className={styles.anotherGift} onClick={() => handleModal(message)}>
				{`Выгода до ${maxBenefit} руб.! `}
				<span>Подробнее</span>
			</div>
		</>
	)
}
