export const models = [
	{
		id: "t2",
		name: "T2",
		benefit: "100 000",
		price: "3 639 000",
		options: [
			{
				title: "Expedition",
				complectation: {
					volume: "2 л",
					power: "245 л.с.",
					rashod: "8 л/100 км",
					razgon: "8.7 сек до 100 км/ч",
					kpp: "Робот с двойным сцеплением",
					privod: "Полный привод",
				},
				description: "",
			},
			{
				title: "Voyage",
				complectation: {
					volume: "2 л",
					power: "245 л.с.",
					rashod: "8 л/100 км",
					razgon: "8.7 сек до 100 км/ч",
					kpp: "Робот с двойным сцеплением",
					privod: "Полный привод",
				},
				description: "",
			},
		],
		colors: [
			{
				text: "Черный",
				hex: "#060a16",
			},
			{
				text: "Серый",
				hex: "#3b414d",
			},
			{
				text: "Коричневый",
				hex: "#c5af8e",
			},
			{
				text: "Серебристый",
				hex: "#69707a",
			},
		],
	},
	{
		id: "dashing",
		name: "Dashing",
		benefit: "570 000",
		price: "1 919 900",
		options: [
			{
				title: "Comfort Plus",
				complectation: {
					volume: "1.5 л",
					power: "147 л.с.",
					rashod: "6.4 л/100 км",
					razgon: "9.3 сек до 100 км/ч",
					kpp: "Механическая",
					privod: "Передний привод",
				},
				description: "",
			},
			{
				title: "Luxury",
				complectation: {
					volume: "1.6 л",
					power: "190 л.с.",
					rashod: "6.4 л/100 км",
					razgon: "9.3 сек до 100 км/ч",
					kpp: "Робот",
					privod: "Передний привод",
				},
				description: "",
			},
			{
				title: "Elite",
				complectation: {
					volume: "1.5 л",
					power: "147 л.с.",
					rashod: "6.4 л/100 км",
					razgon: "9.3 сек до 100 км/ч",
					kpp: "Робот",
					privod: "Передний привод",
				},
				description: "",
			},
			{
				title: "Comfort",
				complectation: {
					volume: "1.5 л",
					power: "147 л.с.",
					rashod: "6.4 л/100 км",
					razgon: "9.3 сек до 100 км/ч",
					kpp: "Робот",
					privod: "Передний привод",
				},
				description: "",
			},
		],
		colors: [
			{
				text: "Черный",
				hex: "#060a16",
			},
			{
				text: "Синий",
				hex: "#37638a",
			},
			{
				text: "Серый",
				hex: "#3b414d",
			},
			{
				text: "Красный",
				hex: "#6d0810",
			},
			{
				text: "Белый",
				hex: "#c5cbd7",
			},
		],
	},
	{
		id: "x70-plus",
		name: "X70 PLUS",
		benefit: "670 000",
		price: "2 379 900",
		options: [
			{
				title: "Comfort",
				complectation: {
					volume: "1.6 л",
					power: "190 л.с.",
					rashod: "8.1 л/100 км",
					razgon: "11.7 сек до 100 км/ч",
					kpp: "Робот",
					privod: "Передний привод",
				},
				description: "",
			},
			{
				title: "Luxury",
				complectation: {
					volume: "1.6 л",
					power: "190 л.с.",
					rashod: "8.1 л/100 км",
					razgon: "11.7 сек до 100 км/ч",
					kpp: "Робот",
					privod: "Передний привод",
				},
				description: "",
			},
		],
		colors: [
			{
				text: "Черный",
				hex: "#060a16",
			},
			{
				text: "Синий",
				hex: "#37638a",
			},
			{
				text: "Серый",
				hex: "#3b414d",
			},
			{
				text: "Зеленый",
				hex: "#28434d",
			},
			{
				text: "Красный",
				hex: "#6d0810",
			},
			{
				text: "Серебристый",
				hex: "#69707a",
			},
			{
				text: "Белый",
				hex: "#c5cbd7",
			},
		],
	},
	{
		id: "x90-plus",
		name: "X90 PLUS",
		benefit: "720 000",
		price: "2 759 900",
		options: [
			{
				title: "Comfort",
				complectation: {
					volume: "1.6 л",
					power: "190 л.с.",
					rashod: "8.1 л/100 км",
					razgon: "11 сек до 100 км/ч",
					kpp: "Робот",
					privod: "Передний привод",
				},
				description: "",
			},
			{
				title: "Elite",
				complectation: {volume: "2 л", power: "245 л.с.", rashod: "8 л/100 км", razgon: "8.5 сек до 100 км/ч", kpp: "Робот", privod: "Передний привод"},
				description: "",
			},
			{
				title: "Luxury",
				complectation: {volume: "2 л", power: "245 л.с.", rashod: "8 л/100 км", razgon: "11 сек до 100 км/ч", kpp: "Робот", privod: "Передний привод"},
				description: "",
			},
			{
				title: "Luxury 7seats",
				complectation: {volume: "2 л", power: "245 л.с.", rashod: "8 л/100 км", razgon: "8.5 сек до 100 км/ч", kpp: "Робот", privod: "Передний привод"},
				description: "",
			},
		],
		colors: [
			{
				text: "Черный",
				hex: "#060a16",
			},
			{
				text: "Синий",
				hex: "#37638a",
			},
			{
				text: "Серый",
				hex: "#3b414d",
			},
			{
				text: "Белый",
				hex: "#c5cbd7",
			},
		],
	},
]

const sharedOptions = [
	{
		title: "Кредит",
	},
	{
		title: "Наличные",
	},
	{
		title: "Трейд-Ин",
	},
]

export const getOptions = (modelId) => [
	...sharedOptions,
	...(modelId !== "t2" ? [{
		title: "Рассрочка 0%",
	}] : [])
]
