import styles from "./ChatHeader.module.scss"
import ChatLogo from "../ChatLogo/ChatLogo"
import {animated, useSpring} from "@react-spring/web"
import {useRef} from "react"
import {CONTACTS} from "../../../utils/constants"
import {useMutationObserver} from "../../../hooks/useMutationObserver"

export default function ChatHeader() {
	const ref = useRef(null)

	const springs = useSpring({
		from: {opacity: 0},
		to: {opacity: 1},
		delay: 500,
		config: {duration: 300},
	})

	useMutationObserver(ref, (mutationRecords) => {
		localStorage.setItem(CONTACTS.phone.storagePhoneKey, JSON.stringify(mutationRecords[0].target.textContent))
	})

	return (
		<>
			<animated.header style={{...springs}} className={styles.root}>
				<div className={styles.container}>
					<div className={`${styles.headerWrap}`}>
						<ChatLogo />
						<div className={styles.contactsWrapper}>
							<div className={styles.contacts}>
								<div className={styles.phone}>
									<a href={CONTACTS.phone.href} className={`${styles.contactsItem} ${styles.tel} ${styles.hide}`} ref={ref}>
										{CONTACTS.phone.title}
									</a>
								</div>
								<a href={CONTACTS.address.href} className={`${styles.contactsItem} ${styles.address}`} target='_blank'>
									<span>{CONTACTS.address.title}</span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</animated.header>
		</>
	)
}
