import styles from "./PrivacyPolicy.module.scss"

export default function PrivacyPolicy() {
	return (
		<div className={styles.root}>
			<div className={styles.title}>Согласие на обработку персональных данных</div>
			<div className={styles.description}>
				<p>
					Настоящим Пользователь Сайта, действуя свободно, своей волей и в своем интересе, а также подтверждая свою дееспособность, дает настоящее
					Согласие на обработку персональных данных (далее – Согласие) Обществу с ограниченной ответственностью «Максимум Кредит» (ОГРН 1147847302954,
					ИНН 7804540104, Юридический адрес: г. Санкт-Петербург, улица Руставели, д. 53, Лит А, помещение 2.8, далее – Оператор).
				</p>
				<p>
					1. Принятием (акцептом) настоящего согласия Пользователь Сайта дает свое согласие на обработку своих персональных данных Оператором.
					Пользователь дает свое согласие на обработку персональных данных на основании следующих условий.
				</p>
				<p>
					2. Согласие дается на обработку следующих персональных данных:
					<ul>
						<li>контактная информация (номер телефона);</li>
						<li>cookie-файлы.</li>
					</ul>
				</p>
				<p>
					3. Целью обработки персональных данных является идентификация Пользователя Сайта для оформления заявок на Сайте с целью приобретения товаров
					и услуг, реализуемых Оператором; предоставление Пользователю доступа к персонализированным ресурсам Сайта; информирование Пользователя о
					товарах, услугах Оператора, специальных предложениях, акциях, осуществления рекламной деятельности, маркетинговых программ, продвижения
					товаров; проведение статистических исследований, а также исследований, направленных на улучшение качества продукции и услуг.
				</p>
				<p>
					4. Пользователь дает согласие на осуществление следующих действий с персональными данными: сбор; запись; систематизация; накопление;
					хранение; уточнение (обновление, изменение); извлечение; использование; передачу (предоставление, доступ); обезличивание; блокирование;
					удаление; уничтожение.
				</p>
				<p>
					5. Пользователь дает согласие на передачу моих персональных данных третьим лицам, с которыми у Оператора имеются действующие договоры, в
					рамках которых Оператор поручает обработку персональных данных в целях, указанных в настоящем согласии.
				</p>
				<p>6. Настоящее согласие действует со дня его подписания до дня отзыва в письменной/электронной форме.</p>
				<p>
					7. Пользователь имеет право отозвать свое согласие посредством направления письма на юридический адрес Оператора или по адресу электронной
					почты info@jetour-maximum.ru
				</p>
				<p>
					8. В случае отзыва субъектом персональных данных или его представителем согласия на обработку персональных данных Оператор вправе продолжить
					обработку персональных данных без согласия субъекта персональных данных при наличии оснований, указанных в пунктах 2 –11 части 1 статьи 6,
					части 2 статьи 10 и части 2 статьи 11 Федеральным законом от 27.07.2006 N 152-ФЗ «О персональных данных»
				</p>
				<p>
					Оператор: ООО «Максимум Кредит», ОГРН 1147847302954, ИНН 7804540104 Юридический адрес: г. Санкт-Петербург, улица Руставели, д. 53, Лит А,
					помещение 2.8, контактный телефон +7(812) 777-77-11; e-mail: info@jetour-maximum.ru.
				</p>
				<p>
					Настоящим Согласием я подтверждаю, что являюсь субъектом предоставляемых персональных данных, а также подтверждаю достоверность
					предоставляемых данных. Я обязуюсь в случае изменения моих персональных данных незамедлительно сообщать об этом Обществу с предоставлением
					подтверждающих документов.
				</p>
				<p>
					Настоящее Согласие признается мной и Оператором моим письменным согласием на обработку персональных данных, данным согласно ст. 9
					Федерального закона от 27.07.2006 г. №152-ФЗ «О персональных данных».
				</p>
				<p>
					Я понимаю, что моя регистрация и/или авторизация в Личном кабинете означает мое письменное согласие с условиями, описанными в настоящем
					Согласии.
				</p>
			</div>

			<div className={styles.title}>Политика Конфиденциальности</div>
			<div className={styles.description}>
				<p>
					Общества с ограниченной ответственностью «Максимум Кредит» (ОГРН 1147847302954, ИНН 7804540104, юридический адрес: г. Санкт-Петербург, улица
					Руставели, д. 53, Лит А, помещение 2.8)
				</p>
				<p>
					Настоящая Политика конфиденциальности персональных данных (далее - Политика конфиденциальности) действует в отношении всей информации,
					размещенной на сайте ООО «Максимум Кредит» в сети Интернет по адресу: sales.jetour-maximum.ru (далее - Сайт), которая может быть получена о
					Пользователях во время использования Сайта, его сервисов, программ и продуктов.
				</p>
				<p>
					Использование сервисов Сайта означает безоговорочное согласие Пользователя с настоящей Политикой и указанными в ней условиями обработки его
					персональной информации; в случае несогласия с этими условиями Пользователь должен воздержаться от использования сервисов.
				</p>
				<h4 className={styles.subTitle}>1. ОБЩИЕ ПОЛОЖЕНИЯ</h4>
				<p>
					<br />
					1.1. В рамках настоящей Политики под персональной информацией Пользователя понимаются:
					<br />
					<br />
					1.1.1. Персональная информация, которую Пользователь предоставляет о себе самостоятельно в процессе использования Сервисов, включая
					персональные данные Пользователя. Такие персональные данные включают контактную информацию (номер телефона); cookie-файлы. Конкретный
					перечень персональных данных указывается в согласии на обработку/распространение персональных данных.
					<br />
					<br />
					1.1.2. Данные, которые автоматически передаются сервисам Сайта в процессе их использования с помощью установленного на устройстве
					Пользователя программного обеспечения, в том числе IP-адрес, данные файлов cookie, информация о браузере Пользователя (или иной программе, с
					помощью которой осуществляется доступ к сервисам), технические характеристики оборудования и программного обеспечения, используемых
					Пользователем, дата и время доступа к сервисам, адреса запрашиваемых страниц и иная подобная информация.
					<br />
					<br />
					1.1.3. Иная информация о Пользователе, обработка которой предусмотрена Соглашением об использовании Сайта.
					<br />
					<br />
					1.1.4. Настоящая Политика конфиденциальности применяется только к настоящему Сайту. Сайт не контролирует и не несет ответственности за сайты
					третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на Сайте.
				</p>
				<h4 className={styles.subTitle}>2. ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЕЙ</h4>
				<p>
					<br />
					2.1. Сайт собирает и хранит только ту персональную информацию, которая необходима для предоставления сервисов или исполнения соглашений и
					договоров с Пользователем, за исключением случаев, когда законодательством предусмотрено обязательное хранение персональной информации в
					течение определенного законом срока.
					<br />
					<br />
					2.2. Персональную информацию Пользователя Сайт обрабатывает в следующих целях:
					<br />
					<br />
					2.2.1. Идентификация Пользователя, оформившего заявку на Сайте, для установления взаимодействия с Пользователем с целью консультирования его
					по вопросам приобретения, продажи или обмена автомобиля в т.ч. по вопросам оформления кредита в Банке, записи автомобиля на сервисные работы
					и др.
					<br />
					<br />
					2.2.2. Предоставление Пользователю доступа к персонализированным ресурсам Сайта.
					<br />
					<br />
					2.2.3. Установление с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Сайта, оказания
					услуг, обработку запросов и заявок от Пользователя.
					<br />
					<br />
					2.2.4. Определение места нахождения Пользователя для обеспечения безопасности, предотвращения мошенничества.
					<br />
					<br />
					2.2.5. Подтверждение достоверности и полноты персональных данных, предоставленных Пользователем.
					<br />
					<br />
					2.2.6. Уведомление Пользователя Сайта о рекламных акциях, предложениях с согласия Пользователя.
					<br />
					<br />
					2.2.7. Предоставление Пользователю эффективной клиентской и технической поддержки при возникновении проблем, связанных с использованием
					Сайта.
					<br />
					<br />
					2.2.8. Осуществление рекламной деятельности с согласия Пользователя, проведение статистических исследований, а также исследований,
					направленных на улучшение качества продукции и услуг.
				</p>
				<h4 className={styles.subTitle}>3. УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЕЙ И ЕЕ ПЕРЕДАЧИ ТРЕТЬИМ ЛИЦАМ</h4>
				<p>
					<br />
					3.1. Сайт хранит персональную информацию Пользователей в соответствии с внутренними регламентами конкретных сервисов.
					<br />
					<br />
					3.2. В отношении персональной информации Пользователя сохраняется ее конфиденциальность, кроме случаев добровольного предоставления
					Пользователем информации о себе для общего доступа неограниченному кругу лиц.
					<br />
					<br />
					3.3. Сайт вправе передать персональную информацию Пользователя третьим лицам в следующих случаях:
					<br />
					<br />
					3.3.1. Пользователь выразил согласие на такие действия.
					<br />
					<br />
					3.3.2. Передача необходима для использования Пользователем определенного сервиса либо для исполнения определенного соглашения или договора с
					Пользователем.
					<br />
					<br />
					3.3.4. Передача предусмотрена российским или иным применимым законодательством в рамках установленной законодательством процедуры.
					<br />
					<br />
					3.3.5. В случае продажи Сайта к приобретателю переходят все обязательства по соблюдению условий настоящей Политики применительно к
					полученной им персональной информации.
					<br />
					<br />
					3.4. Обработка персональных данных Пользователя осуществляется без ограничения срока любым законным способом, в том числе в информационных
					системах персональных данных с использованием средств автоматизации или без использования таких средств. Обработка персональных данных
					Пользователей осуществляется в соответствии с Федеральным законом от 27.07.2006 N 152-ФЗ «О персональных данных».
					<br />
					<br />
					3.5. При утрате или разглашении персональных данных Администрация Сайта информирует Пользователя об утрате или разглашении персональных
					данных.
					<br />
					<br />
					3.6. Администрация Сайта принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от
					неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных
					действий третьих лиц.
					<br />
					<br />
					3.7. Администрация Сайта совместно с Пользователем принимает все необходимые меры по предотвращению убытков или иных отрицательных
					последствий, вызванных утратой или разглашением персональных данных Пользователя.
					<br />
					<br />
					3.8. На Сайте обрабатываются cookie — это файлы, которые позволяют сохранять определенную информацию о Пользователях и устройствах
					Пользователей при посещении Сайта, они помогают определить количество посетителей Сайта, частоту посещений Сайта, а также помогают
					адаптировать предложения Сайта под индивидуальные запросы Пользователя. Продолжая использовать Сайт, Пользователь дает свое согласие Сайту
					на обработку cookie.
				</p>

				<h4 className={styles.subTitle}>4. ОБЯЗАТЕЛЬСТВА СТОРОН</h4>
				<p>
					<br />
					4.1. Пользователь обязан:
					<br />
					<br />
					4.1.1. Предоставить информацию о персональных данных, необходимую для пользования Сайтом.
					<br />
					<br />
					4.1.2. Обновлять, дополнять предоставленную информацию о персональных данных в случае изменения данной информации.
					<br />
					<br />
					4.2. Администрация Сайта обязана:
					<br />
					<br />
					4.2.1. Использовать полученную информацию исключительно для целей, указанных в настоящей Политике конфиденциальности.
					<br />
					<br />
					4.2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а
					также не осуществлять продажу, обмен, опубликование либо разглашение иными возможными способами переданных персональных данных Пользователя,
					за исключением предусмотренных настоящей Политикой конфиденциальности.
					<br />
					<br />
					4.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя согласно порядку, обычно используемому
					для защиты такого рода информации в существующем деловом обороте.
					<br />
					<br />
					4.2.4. Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса
					Пользователя или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки в
					случае выявления недостоверных персональных данных или неправомерных действий.
				</p>

				<h4 className={styles.subTitle}>5. ОТВЕТСТВЕННОСТЬ СТОРОН</h4>
				<p>
					<br />
					5.1. Администрация Сайта, не исполнившая свои обязательства, несет ответственность за убытки, понесенные Пользователем в связи с
					неправомерным использованием персональных данных, в соответствии с законодательством Российской Федерации.
					<br />
					<br />
					5.2. В случае утраты или разглашения конфиденциальной информации Администрация Сайта не несет ответственности, если данная конфиденциальная
					информация:
					<br />
					<br />
					5.2.1. Стала публичным достоянием до ее утраты или разглашения.
					<br />
					<br />
					5.2.2. Была получена от третьей стороны до момента ее получения Администрацией Сайта.
					<br />
					<br />
					5.2.3. Была разглашена с согласия Пользователя.
				</p>

				<h4 className={styles.subTitle}>6. РАЗРЕШЕНИЕ СПОРОВ</h4>
				<p>
					<br />
					6.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем Сайта и Администрацией Сайта, обязательным является
					предъявление претензии (письменного предложения о добровольном урегулировании спора).
					<br />
					<br />
					6.2. Получатель претензии в течение 10 календарных дней со дня получения претензии письменно уведомляет заявителя претензии о результатах
					рассмотрения претензии.
					<br />
					<br />
					6.3. При недостижении соглашения спор будет передан на рассмотрение в суд в соответствии с действующим законодательством Российской
					Федерации.
					<br />
					<br />
					6.4. К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией Сайта применяется действующее
					законодательство Российской Федерации.
				</p>

				<h4 className={styles.subTitle}>7. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</h4>
				<p>
					<br />
					7.1. Администрация Сайта вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователя.
					<br />
					<br />
					7.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте, если иное не предусмотрено новой редакцией Политики
					конфиденциальности.
					<br />
					<br />
					7.3. Обращения, предложения и претензии, связанные с содержанием и функционированием Сайта, могут быть направлены по электронному адресу:
					info@jetour-maximum.ru или по адресу: 195299 г. Санкт-Петербург, улица Руставели, д. 53, Лит А, помещение 2.8.
					<br />
					<br />
					7.4. Действующая Политика конфиденциальности размещена на странице по адресу: sales.jetour-maximum.ru/privacy-policy/
				</p>
			</div>
		</div>
	)
}
