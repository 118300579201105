import {GLOBAL_BRAND} from "../../../utils/constants"
import styles from "./ChatLogo.module.scss"

export default function ChatLogo() {
	return (
		<div className={styles.root}>
			<img alt='logo' className={styles.image} src='/images/logo/black.svg' width={249} height={35} />
			<div className={styles.divider} />
			<p className={styles.text}>
				Официальный дилер <br /> {GLOBAL_BRAND} Максимум
			</p>
		</div>
	)
}
