export const GLOBAL_BRAND = "JETOUR"

export const CONTACTS = {
	phone: {
		title: "+7 (812) 501-10-30",
		href: "tel:+78125011030",
		storagePhoneKey: "jetour-chat-gtm-phone",
	},
	address: {
		title: "Санкт-Петербург, Транспортная территория, 6",
		titleShort: "Транспортная территория, 6",
		href: "https://yandex.ru/maps/-/CDsCrIPp",
	},
}
