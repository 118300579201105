import {animated, useTransition} from "@react-spring/web"
import {CONTACTS, GLOBAL_BRAND} from "../../../../utils/constants"

import styles from "./BotInfo.module.scss"

export default function BotInfo({isBlockVisible}) {
	const transition = useTransition(isBlockVisible, {
		from: {
			y: -125,
		},
		enter: {
			y: 0,
		},
		leave: {
			y: -125,
		},
		config: {duration: 150},
	})

	return transition((style, isBlockVisible) => (isBlockVisible ? <Content style={style} /> : null))
}

const Info = (props) => {
	const GTMPhone = JSON.parse(localStorage.getItem(CONTACTS.phone.storagePhoneKey)) || CONTACTS.phone.title
	const phoneHref = `tel:+${GTMPhone.replace(/\D/g, "")}`
	return (
		<div className={styles.fixedBotInfo} {...props}>
			<div className={styles.botInfo}>
				<div className={styles.image}>
					<img src='/images/bot.png' alt='' width={100} height={100} />
				</div>
				<div className={styles.botPost}>
					Онлайн-консультант <br /> автосалона {GLOBAL_BRAND} МАКСИМУМ
				</div>
			</div>
			<a href={phoneHref} className={styles.button}>
				<span>Позвонить</span>
				<span className={styles.phone}>: {GTMPhone}</span>
			</a>
		</div>
	)
}

const Content = animated(Info)
