import styles from "./ChatFooter.module.scss"

export default function ChatFooter() {
	return (
		<footer className={styles.root}>
			<div className={styles.footerWrap}>
				<div>*Цены и выгоды указаны для автомобилей в лизинг.</div>
				ООО «Максимум Кредит»
				<br />
				ИНН 7804540104
				<br />
				ОГРН 1147847302954 <br />
				Адрес: 195299 г. Санкт-Петербург, <br /> улица Руставели, дом 53
			</div>
		</footer>
	)
}
