import {useState} from "react"

import styles from "./Options.module.scss"
import ChatIcon from "../../ChatIcon/ChatIcon"
import MoreInfo from "./MoreInfo/MoreInfo"

export default function Options({option, setFunction, isComplectation = false}) {
	const [isActive, setIsActive] = useState(false)
	const [isMoreInfoOpen, setIsMoreInfoOpen] = useState(false)
	const {title} = option

	const onCheckBoxClick = () => {
		setIsActive(!isActive)
		if (isActive) {
			setFunction((prev) => prev.filter((i) => i !== title))
		} else {
			setFunction((prev) => [...prev, title])
		}
	}

	const openMoreInfo = () => setIsMoreInfoOpen(true)
	const closeMoreInfo = () => setIsMoreInfoOpen(false)

	return (
		<>
			<div className={styles.wrapper}>
				<button className={`${styles.radio} ${isActive ? styles.radioActive : ""}`} onClick={onCheckBoxClick}>
					{isActive ? <ChatIcon.CheckOn /> : <ChatIcon.CheckOff />}
					<p className={styles.text}>{title}</p>
				</button>
				{isComplectation && (
					<button className={styles.button} onClick={openMoreInfo}>
						Подробнее
					</button>
				)}
			</div>
			{isComplectation && <MoreInfo option={option} closeMoreInfo={closeMoreInfo} isMoreInfoOpen={isMoreInfoOpen} />}
		</>
	)
}
