import {GLOBAL_BRAND} from "./constants"

export default function apiRequest(phone, message, depType, isPhoneCall = false, model = GLOBAL_BRAND) {
	const data = {
		name: "Ввод не предусмотрен",
		phone,
		message: message,
		model,
		target: message,
		url: window.location.href,
	}
	const Comagic = window.Comagic

	if (isPhoneCall) {
		fetch("https://maximum-auto.ru/api/leadcollector/", {
			method: "POST",
			body: JSON.stringify({
				brand: GLOBAL_BRAND,
				communication_type: "site",
				date_time: new Date(),
				dep_type: depType,
				name: "Ввод не предусмотрен",
				phone_number: phone,
				message: message,
				model: model,
			}),
		})
			.then((res) => {
				console.info("Заявка отправлена в ЛК")
			})
			.catch(console.error)
	}
	try {
		if (!isPhoneCall) {
			Comagic.sitePhoneCall({
				phone: phone,
			})
		} else {
			Comagic.addOfflineRequest(data)
		}
	} catch (error) {
		console.warn(`Отправка в Comagic не удалась. Причина ${error}`)
	}
}
